@use "src/styles" as *;

.search-count {
    padding-block-start: var(--spacer-5);
    padding-block-end: var(--spacer-2);

    @include for-size(laptop-up) {
        padding-block: var(--spacer-6);
    }

    &__skeleton-wrapper {
        width: 100%;
        max-width: 28rem;
        margin-inline: auto;
    }

    &__skeleton {
        height: 1.5rem;
    }

    &__label {
        @extend %heading-1;

        display: flex;
        justify-content: center;
        padding: 0;
        font-size: 1.75rem;
        font-weight: var(--fw-bold);
        color: var(--c-green-dark);
        text-align: center;
    }
}
