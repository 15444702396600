@use "src/styles" as *;

.page-suggestions {
    padding-block-start: var(--spacer-4);

    &__label {
        font-size: .875rem;
        font-weight: var(--fw-bold);
    }

    &__no-results {
        padding: var(--spacer-3);
    }

    &__no-results,
    &__list {
        margin-block-start: var(--spacer-4);
    }

    &__list-item {
        @extend %button--no-background;

        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: var(--spacer-2);
        width: 100%;
        min-height: 3.25rem;
        padding-block: var(--spacer-2);
        padding-inline: var(--spacer-3);
        font-weight: var(--fw-regular);
        text-align: start;
        color: var(--c-green-dark);
        border-block-end: 1px solid var(--c-green-75);
    }
}
