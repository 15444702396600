@use "src/styles" as *;

$component: "product-collection-section";

.#{$component} {
    --c-product-collection-title: var(--c-outdoor-2);
    --c-production-collection-button-text: var(--c-white);
    --c-production-collection-button-background: var(--c-green-dark);

    margin-block-start: var(--spacer-5);

    &__header {
        position: relative;
        display: flex;
        align-items: flex-end;
        gap: var(--spacer-3);
    }

    &__header-title {
        @extend %heading-3;

        color: var(--c-product-collection-title);
        word-break: normal;

        @include for-size(tablet-portrait-up) {
            flex-shrink: 0;
        }
    }

    &__header-divider {
        @extend %divider-start-end;

        margin-block-end: negative(var(--spacer-3));
        color: var(--c-black);
    }

    &__items {
        display: grid;
        grid-gap: var(--spacer-4);
        margin-block-start: var(--spacer-6);

        @include for-size(tablet-portrait-up) {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    &__item {
        position: relative;

        &:first-child {

            .#{$component}__item-picture {
                height: 15rem;

                @include for-size(tablet-portrait-up) {
                    height: 41.5rem;
                }
            }

            @include for-size(tablet-portrait-up) {
                grid-column: span 2;
            }
        }
    }

    &__item-picture {
        width: 100%;
        height: 30rem;

        @include for-size(tablet-portrait-up) {
            height: 41.5rem;
        }
    }

    &__item-image {
        object-fit: cover;
    }

    &__item-link {
        position: absolute;
        left: 50%;
        bottom: var(--spacer-3);
        white-space: nowrap;
        color: var(--c-production-collection-button-text);
        background-color: var(--c-production-collection-button-background);
        translate: -50%;

        @include for-size(tablet-portrait-up) {
            bottom: var(--spacer-6);
        }
    }

    &__product-list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: var(--spacer-4);
        grid-column-gap: var(--spacer-4);
        margin-block-start: var(--spacer-4);

        @include for-size(tablet-portrait-up) {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    &__product-item {

        &:first-child {
            grid-column: span 2;

            .#{$component}__products-item-picture {
                height: 16rem;

                @include for-size(tablet-portrait-up) {
                    height: 25rem;
                }
            }

            @include for-size(tablet-portrait-up) {
                grid-column: span 1;
            }
        }
    }

    &__product-item-picture {
        height: 10rem;

        @include for-size(tablet-portrait-up) {
            height: 25rem;
        }
    }

    &__footer {
        margin-block-start: var(--spacer-3);
    }
}
