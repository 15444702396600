.range-filter-accordion {
    width: 100%;
    padding-inline-start: 0;

    &__heading {
        font-family: var(--f-primary);
        font-weight: var(--fw-regular);
        line-height: 1.125;
        color: var(--c-black);
        text-transform: capitalize;
    }

    &__content {
        padding-block: var(--spacer-5) var(--spacer-4);
        padding-inline: var(--spacer-3);
    }
}
