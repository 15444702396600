.searchable-value-input {
    position: relative;

    &__result-list {
        position: absolute;
        z-index: 21;
        max-height: 22em;
        margin: 0;
        padding: 0;
        overflow: auto;
        border-radius: var(--border-radius);
        border-start-start-radius: 0;
        border-start-end-radius: 0;
        border-block-start: none;
        background-color: var(--c-background);
        box-shadow: 0 1px 2px 1px var(--c-green-medium);
    }

    &__result-option {

        & + & {
            border-block-start: var(--line);
        }
    }

    &__error-label {
        margin-block-start: var(--spacer-2);
    }
}
