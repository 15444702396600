@use "src/styles" as *;

.my-data {

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-block-end: var(--spacer-5);
        height: var(--spacer-6);
    }

    &__heading {
        @extend %heading-6;
    }

    &__edit-button {
        @extend %button--no-background;

        color: var(--c-green-dark);
        font-weight: var(--fw-regular);
    }

    &__form {
        display: flex;
        justify-content: space-between;
        column-gap: var(--spacer-4);
        flex-direction: column;

        @include for-size(laptop-up) {
            flex-direction: row;
        }
    }

    &__form-section {
        width: 100%;
        margin-block-start: var(--spacer-6);

        @include for-size(laptop-up) {
            margin-block-start: 0;
        }

        &--company {
            display: none;
        }
    }

    &__fieldset {
        border: none;
        padding: 0;
        margin: 0;

        @include for-size(laptop-up) {
            margin-block-start: 0;
        }
    }

    &__input {
        margin-block-end: var(--spacer-3);
        width: 100%;

        &:first-of-type {
            margin-block-start: var(--spacer-3);
        }
    }

    // Styling Text Input as a normal label + value
    &__input-label {
        color: var(--c-green-dark);
        font-weight: var(--fw-light);
    }

    &__input-field {
        transition: padding .2s ease-in-out;
        font-weight: var(--fw-light);

        &:disabled {
            padding-inline: 0;
            padding: 0;
            cursor: default;
            border: 1px solid transparent;
            background-color: transparent;
        }
    }
    // End styling TextInput

    &__password-input {
        display: none;

        &--show-icon {
            display: block;
        }
    }

    &__address-input-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: var(--spacer-3);
        width: fit-content;
    }
}
