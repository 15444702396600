.iframe {
    width: 100%;
    height: 75dvh;

    &__block {
        width: 100%;
        height: 100%;
        border: unset;
    }
}
