@use "src/styles" as *;

.store-locator {
    display: flex;
    flex-direction: column;
    gap: var(--spacer-3);

    &__title {
        color: var(--c-green-dark);
        margin-block-start: var(--spacer-4);

        @include for-size(tablet-portrait-up) {
            margin-block-end: var(--spacer-4);
        }
    }

    &__map {
        flex: 1;
        width: 100%;
        aspect-ratio: 4/3;
        background-color: var(--c-green-medium);
    }

    &__search-input {
        border-bottom: 1px solid var(--c-black);
    }

    &__search-input-wrapper {
        column-gap: var(--spacer-3);
    }

    &__search-input-field {
        border: none;
        padding-inline: 0;
    }

    &__search-input-icon-wrapper {
        padding: 0;
    }

    &__search-input-icon {
        width: 1.25rem;
        height: 1.25rem;
    }

    &__current-location-button {
        @extend %button--no-background;

        color: var(--c-black);
        font-size: 1rem;
        font-weight: var(--fw-regular);
        padding: 0;
        padding-block: var(--spacer-3);
    }

    &__current-location-error {
        color: var(--c-error);
    }
}
