@use "src/styles" as *;

.order-detail-renderer {

    &__title {
        @extend %heading-6;

        line-height: 1.33;
    }

    &__wrapper {
        margin-block-start: var(--spacer-4);

        @include for-size(tablet-landscape-up) {
            display: grid;
            grid-template-columns: 1fr 2fr;
        }
    }

    &__product-variant-list {
        display: flex;
        flex-direction: column;
        gap: var(--spacer-3);
    }

    &__detail-wrapper {
        margin-block-start: var(--spacer-5);

        @include for-size(tablet-landscape-up) {
            margin-block-start: unset;
            padding-inline-start: var(--spacer-4);
            border-inline-start: 1px solid var(--c-green-75);
        }
    }

    &__info-block {

        & + & {
            margin-block-start: var(--spacer-4);
        }

        &--with-tooltip {
            display: flex;
        }
    }

    &__labelled-value {

        & + & {
            margin-block-start: var(--spacer-3);
        }
    }

    &__price-table {
        margin-block-start: var(--spacer-3);
    }

    &__address {
        margin-block-start: var(--spacer-3);
    }

    &__tooltip-wrapper {
        @extend %flex-align-center;

        margin-inline-start: var(--spacer-2);
    }

    &__tooltip {
        width: max-content;
        max-width: 32rem;
    }

    &__tooltip-icon {
        height: 1.5rem;
        color: var(--c-green);
    }
}
