.product-price {

    &__price {

        &--is-old {
            color: var(--c-green-dark);
            text-decoration: line-through;
            text-decoration-thickness: 1px;
        }

        &--is-sale {
            margin-inline-start: var(--spacer-2);
        }
    }
}
