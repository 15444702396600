@use "src/styles" as *;

$component: "product-card";

.#{$component} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;

    &__remove-from-wishlist-button-wrapper {
        width: 100%;
        background-color: var(--c-white);
    }

    &__remove-from-wishlist-button {
        @extend %button--no-background;

        margin-inline-start: auto;
        color: var(--c-black);
        padding: var(--spacer-2);
    }

    &__picture-wrapper {
        @extend %flex-align-center;

        position: relative;
        width: 100%;
        border: 1px solid transparent;
        background-color: var(--c-white);

        &--is-grid-view {
            height: unset;
            aspect-ratio: 1;
        }

        @include for-size(laptop-up) {

            &:hover,
            &:focus-within {

                .#{$component}__image-switch-primary {
                    opacity: 0;
                }

                .#{$component}__image-switch-secondary {
                    opacity: 1;

                    &--is-sold-out {
                        opacity: .3;
                    }
                }

                .#{$component}__buttons {
                    opacity: 1;
                    pointer-events: all;
                }
            }
        }
    }

    &__picture-inner-wrapper {
        @extend %flex-align-center;

        width: 100%;

        &::after {
            @extend %area-extend;

            content: "";
        }
    }

    &__labels {
        display: flex;
        gap: var(--spacer-3);
        flex-wrap: wrap;
        position: absolute;
        top: var(--spacer-3);
        left: var(--spacer-3);
    }

    &__image-default {
        width: 100%;
        aspect-ratio: 1;

        &--is-fallback {
            width: 50%;
            height: 50%;
        }

        &--is-sold-out {
            opacity: .3;
        }
    }

    &__image-switch-primary {
        width: 100%;
        opacity: 1;
        transition: opacity .25s ease-in-out;

        &--is-sold-out {
            opacity: .3;
        }
    }

    &__image-switch-secondary {
        position: absolute;
        width: 100%;
        height: 100%;
        transition: opacity .25s ease-in-out;
        opacity: 0;
    }

    &__buttons {
        @extend %flex-align-center;

        display: none;
        visibility: hidden;
        width: 100%;
        height: 2.5rem;
        position: absolute;
        bottom: 0;
        margin: var(--spacer-3);
        padding: 0 var(--spacer-3);
        column-gap: var(--spacer-2);
        transition: opacity .25s ease-in-out;

        @include for-size(tablet-portrait-up) {
            display: flex;
            visibility: visible;
        }

        @include for-size(laptop-up) {
            opacity: 0;
            pointer-events: none;
        }
    }

    &__cart-button {
        width: 100%;
        padding: var(--spacer-2) var(--spacer-5);

        &:disabled {
            background-color: var(--c-shade-opaque);
        }
    }

    &__wishlist-button {
        width: 2.5rem;
        height: 100%;
        padding: var(--spacer-3) var(--spacer-4);
        background-color: var(--c-white);
        border-color: var(--c-green);

        &:enabled:hover {
            background-color: var(--c-white);
        }
    }

    &__info {
        margin-block-start: var(--spacer-3);
    }

    &__brand-link {
        @include increase-clickable-area;

        position: relative;
        font-family: var(--f-primary);
        font-weight: var(--fw-medium);
        font-size: .75rem;
        letter-spacing: normal;
        text-transform: uppercase;
    }

    &__product-link {
        display: block;
        margin-block-start: var(--spacer-2);
        font-family: var(--f-primary);
        font-weight: var(--fw-light);
        font-size: 1rem;
        hyphens: auto;
    }

    &__price {
        margin-block-start: var(--spacer-2);
    }

    &__color-swatches {
        align-items: center;
        gap: var(--spacer-2);
        margin-block-start: var(--spacer-2);
    }

    &__colors-list {
        display: flex;
        gap: var(--spacer-1);
    }

    &__colors-item {
        @include circle(1rem);

        box-shadow: 0 0 .5rem rgba(0, 0, 0, .1);
    }

    &__variants {
        font-size: .875rem;
        font-weight: var(--fw-light);
        margin-block-start: var(--spacer-2);
        line-height: normal;
        color: var(--c-black);
    }

    &__variants-amount {
        font-weight: var(--fw-regular);
    }
}
