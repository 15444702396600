@use "src/styles" as *;

.brand-header {

    &__heading {
        @extend %heading-3;

        max-width: 21rem;
        margin-block-end: var(--spacer-6);

        @include for-size(tablet-portrait-up) {
            max-width: 35rem;
        }
    }

    &__content-wrapper {
        display: grid;
        grid-template-columns: 1fr;

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
            grid-template-columns: repeat(2, 1fr);
        }
    }

    &__logo {
        justify-self: center;
        max-width: 12rem;
        height: 12rem;
        padding: var(--spacer-5);
    }

    &__description {
        padding-block-start: var(--spacer-4);

        @include for-size(tablet-portrait-up) {
            max-width: 35rem;
            padding-block-start: unset;
            padding-inline-start: var(--spacer-5);
        }

        &--has-image {
            border-block-start: 1px solid var(--c-black);

            @include for-size(tablet-portrait-up) {
                border-block-start: unset;
                border-inline-start: 1px solid var(--c-black);
            }
        }
    }
}
