@use "src/styles" as *;

.checkout-navigation {
    --c-main: var(--c-green-50);
    --circle-width: 1.5em;
    --line-thickness: 2px;

    &__list {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 50vw;
        max-width: 34.25rem;
        padding: 0;
        margin-block: var(--spacer-4);
        margin-inline: auto;
        overflow: hidden;
        background-image: linear-gradient(var(--c-main) 0 0);
        background-repeat: no-repeat;
        background-position: 50% calc((var(--circle-width) - var(--line-thickness)) / 2);
        background-size: 100% var(--line-thickness);
    }
}
