@use "src/styles" as *;

.cookie-settings {
    position: fixed;
    inset-block-end: 0;
    inset-inline-end: 0;
    z-index: 10;
    width: fit-content;
    padding: var(--wrapper-padding);
    pointer-events: none;

    &__card {
        max-width: 34rem;
        max-height: calc(100dvh - var(--menu-height) - var(--submenu-height));
        overflow-y: auto;
        padding: var(--spacer-5);
        pointer-events: all;
        border-radius: .25rem;
        background-color: var(--c-white);
        box-shadow: var(--box-shadow);
        transition: translate .3s var(--ease-out-strong), opacity .3s ease;
        translate: calc(100% + var(--wrapper-padding)) 0;
        opacity: 0;

        &--is-revealed {
            translate: 0 0;
            opacity: 1;
        }
    }

    &__title {
        @extend %heading-5;
    }

    &__description {
        margin-block-start: var(--spacer-3);
        font-size: .875rem;
    }

    &__link {
        display: block;
        width: fit-content;
        margin-block-start: var(--spacer-2);
        font-weight: var(--fw-medium);
        line-height: 1.5;
        text-decoration: underline;
    }

    &__toggle-wrapper {
        display: flex;
        flex-direction: column;
        gap: var(--spacer-3);
        margin-block-start: var(--spacer-3);
    }

    &__button-wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: var(--spacer-3);
        margin-block-start: var(--spacer-3);
    }

    &__button {
        width: fit-content;
        padding-block: var(--spacer-2);
        padding-inline: var(--spacer-4);

        &--is-reject {
            @extend %button--is-outline;

            padding-inline: var(--spacer-3);
            color: var(--c-black);
        }
    }
}
