@use "src/styles" as *;

.search-results {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: var(--spacer-5);
    width: 100%;

    @include for-size(laptop-up) {
        flex-direction: row;
        gap: var(--spacer-6);
    }

    &__recent-searches {

        @include for-size(laptop-up) {
            min-width: 12rem;
        }
    }

    &__product-results {
        width: 100%;
    }

    &__page-results {

        @include for-size(laptop-up) {
            min-width: 16rem;
        }
    }
}
