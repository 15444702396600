@use "src/styles" as *;

.account-navigation-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    min-width: max-content;
    font-weight: var(--fw-regular);
    color: var(--c-black);

    &__link {
        --c-link-icon: var(--c-green-medium);

        display: flex;
        gap: var(--spacer-2);
        position: relative;
        width: 100%;
        padding: var(--spacer-2);
        overflow: clip;
        text-decoration: none;
        color: inherit;
        border-radius: .5rem;

        &::after {
            @extend %area-extend;

            content: "";
            z-index: 10;
            background-color: var(--c-green-25);
            transition: opacity .1s ease;
            opacity: 0;
        }

        &--is-active {
            --c-link-icon: var(--c-black);

            font-weight: var(--fw-bold);
            color: var(--c-black);

            &::after {
                opacity: 1;
            }
        }
    }

    &__icon,
    &__label {
        position: relative;
        z-index: 20;
    }

    &__icon {
        height: 1.25rem;
        color: var(--c-link-icon);
    }
}
