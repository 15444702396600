@use "src/styles" as *;

.cart-item {

    &:not(:first-of-type) {
        padding-block-start: var(--spacer-3);
    }

    &__button {
        @extend %button--no-background;

        margin-inline-start: auto;
        padding: 0;
        color: var(--c-black);

        &::after {
            content: unset;
        }
    }

    &__info-wrapper {
        display: flex;
        gap: var(--spacer-4);
        align-items: center;
    }

    &__image {
        @include square(10.5rem);

        min-width: 5.5rem;

        @include for-size(tablet-portrait-up) {
            @include square(8.5rem);
        }
    }

    &__content-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--spacer-2);
    }

    &__name {
        font-weight: var(--fw-medium);
    }

    &__price-wrapper {
        display: flex;
        gap: var(--spacer-3);
    }

    &__quantity,
    &__price {
        color: var(--c-green-dark);
    }

    &__quantity-input {
        width: 7.5rem;
    }

    &__quantity-button {
        padding: var(--spacer-2);
    }

    &__buttons-wrapper {
        display: flex;
        height: 100%;
        align-items: center;
        gap: var(--spacer-2);
    }

    &__icon-button {
        @extend %button--no-background;

        padding: var(--spacer-2);
        color: var(--c-black);
        border: none;

        &::after {
            opacity: 0;
        }
    }
}
