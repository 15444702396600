@use "src/styles" as *;

.product-brand {

    &__wrapper {
        max-width: unset;
        margin-block-start: var(--spacer-5);
        padding-inline: unset;

        @include for-size(tablet-portrait-up) {
            max-width: var(--wrapper-width);
            padding-inline: var(--wrapper-padding);
        }
    }

    &__card {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: var(--spacer-3);
        padding: var(--spacer-5);
        background-color: var(--c-white);

        @include for-size(tablet-portrait-up) {
            gap: var(--spacer-8);
            flex-direction: row;
            padding: var(--spacer-5) var(--spacer-8);
        }
    }

    &__logo {
        flex: 2;
        display: flex;
        justify-content: flex-end;
    }

    &__information {
        flex: 3;
        padding-block: var(--spacer-5);
    }

    &__name {
        margin-block-end: var(--spacer-3);
        font-family: var(--f-primary);
        font-size: .75rem;
        font-weight: var(--fw-medium);
    }

    &__link-button {
        margin-block-start: var(--spacer-4);
    }

    &__slider {
        margin-block-start: var(--spacer-8);
    }

    &__slider-product {
        height: 100%;
    }

    &__product-picture-wrapper {
        aspect-ratio: 1;
    }
}
