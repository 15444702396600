@use "src/styles" as *;

.wayfinding-wrapper {

    &--is-disabled {
        visibility: hidden;
    }

    &__icon-wrapper {
        --wayfinding-icon-x: 0px;
        --wayfinding-icon-y: 0px;

        position: fixed;
        top: var(--wayfinding-icon-y);
        left: var(--wayfinding-icon-x);
        pointer-events: none;
        transition: height calc(.2s * var(--transition-multiplier)) ease, opacity .1s ease;
        will-change: left, top;
        translate: -50% -50%;
        height: 0;
        opacity: 0;

        @include touch-screen {
            display: none;
        }

        &--is-revealed {
            height: 7.5rem;
            opacity: 1;
        }
    }

    &__icon {
        height: inherit;
        stroke-width: 1px;
    }

    &__child-wrapper > * {
        cursor: none;
    }
}
