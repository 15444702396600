@use "src/styles" as *;

.desktop-menu {

    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: var(--spacer-2);
        padding-block: var(--spacer-5);
        height: 5.5rem;
    }

    &__main-navigation {
        flex: 1;
    }

    &__logo {
        width: 3.5rem;
        height: 3.5rem;
    }

    &__controls {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: var(--spacer-2);
    }

    &__link {
        @extend %button--no-background;

        padding: var(--spacer-2);
    }

    &__link-icon {
        width: 1.5rem;
        height: 1.5rem;
        color: var(--c-black);
        background: none;
    }

    &__account-avatar {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10rem;
        color: var(--c-green-50);
        font-weight: var(--fw-bold);
        background-color: var(--c-green-dark);
    }
}
