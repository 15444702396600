@use "src/styles" as *;

$component: "start-step";

.#{$component} {
    display: flex;
    flex-direction: column;
    gap: var(--spacer-5);

    @include for-size(tablet-portrait-up) {
        flex-direction: row;
    }

    &__accordion-summary {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: var(--spacer-3) var(--spacer-5);

        &::marker,
        &::-webkit-details-marker {
            display: none;
        }

        @include for-size(tablet-portrait-up) {
            display: none;
        }
    }

    &__accordion-heading {
        display: flex;
        align-items: center;
        gap: var(--spacer-3);
    }

    &__accordion-icon {
        width: 1.5rem;
        height: 1.5rem;
        margin-inline-start: auto;
        color: var(--c-green-medium);
        transition: scale .1s ease;
    }

    &__accordion-price {
        font-size: 1.5rem;
        font-weight: var(--fw-regular);
    }

    // Left section

    &__left-section {
        flex: 1;
        padding-block-start: var(--spacer-2);

        @include for-size(tablet-portrait-up) {
            background-color: var(--c-white);
            padding: var(--spacer-5);
        }

        &[open] > .#{$component}__accordion-summary .#{$component}__accordion-icon {
            scale: 1 -1;
        }
    }

    &__left-section-wrapper {
        padding: var(--spacer-5);
        background-color: var(--c-white);
        margin-block-start: 2rem;

        @include for-size(tablet-portrait-up) {
            padding: unset;
            margin-block-start: unset;
        }
    }

    &__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-block-end: var(--spacer-4);

        @include for-size(tablet-portrait-up) {
            margin-block-end: var(--spacer-5);
        }
    }

    &__heading {
        @extend %heading-6;
    }

    &__clear-cart-button {
        @extend %button--link;

        font-weight: var(--fw-light);
        color: var(--c-black);

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }

    &__product-column-definitions {
        display: none;

        @include for-size(laptop-up) {
            display: flex;
            justify-content: space-between;
        }
    }

    &__product-list {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: var(--spacer-3);
    }

    &__product {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__no-products-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: var(--spacer-3);
        margin-block: var(--spacer-5);
        text-align: center;
    }

    &__no-products-icon {
        height: 3rem;
        color: var(--c-green-medium);
    }

    &__no-products-text {
        @extend %heading-5;

        color: var(--c-green-medium);
    }

    // Right section

    &__right-section {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: var(--spacer-5);
        padding: var(--spacer-5);
        background-color: var(--c-white);
    }

    &__usp-list {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: var(--spacer-4);
        padding: var(--spacer-5) var(--spacer-3);
        background-color: var(--c-background);
    }

    &__usp-item {
        display: flex;
        column-gap: var(--spacer-3);
        font-weight: var(--fw-regular);
    }

    &__usp-item-icon {
        width: 1.25rem;
        height: 1.25rem;
    }

    &__buttons-wrapper {
        display: flex;
        flex-direction: column;
        gap: var(--spacer-3);
    }

    &__button {
        width: 100%;
    }
}
