@use "src/styles" as *;

$component: cart-sidebar;

.#{$component} {

    &__dialog {
        width: 80vw;
        min-height: 100vh;
        margin: 0;
        margin-inline-start: auto;

        &--layout-mini {
            min-height: unset;
            height: fit-content;
            margin-inline-end: 2rem;
            margin-block-start: 8rem;
            border-radius: .25rem;
            box-shadow: 0 0 1px 1px var(--c-green);
        }

        @include for-size(tablet-portrait-up) {
            width: 24rem;
            padding: var(--spacer-4);
        }
    }

    &__heading {
        @extend %heading-6;
    }

    &__no-products-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: var(--spacer-3);
        margin-block: var(--spacer-5);
        text-align: center;
    }

    &__no-products-icon {
        height: 2rem;
        color: var(--c-green-dark);
    }

    &__no-products-text {
        @extend %heading-5;

        color: var(--c-green-dark);
    }

    &__close-button {
        margin-block-end: var(--spacer-4);
        margin-inline-start: auto;
        padding: unset;
        color: var(--c-black);
        background: none;
    }

    &__items-wrapper {
        display: flex;
        flex-direction: column;
        margin-block-start: var(--spacer-4);
        max-height: 45vh;
        overflow-y: auto;
    }

    &__cart-item {
        padding-block-end: var(--spacer-3);

        &:not(:last-of-type) {
            border-block-end: 1px solid var(--c-green);
        }
    }

    &__info-wrapper {
        display: flex;
        flex-direction: row;
        column-gap: var(--spacer-2);
    }

    &__usp-list {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: var(--spacer-4);
        padding: var(--spacer-5) var(--spacer-3);
        background-color: var(--c-background);
    }

    &__usp-item {
        display: flex;
        column-gap: var(--spacer-3);
        font-weight: var(--fw-regular);
    }

    &__usp-item-icon {
        width: 1.25rem;
        height: 1.25rem;
    }

    &__price-wrapper,
    &__total-price-wrapper {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        margin-block-start: var(--spacer-3);

        &--has-border {
            border-block-start: 1px solid var(--c-green);
            padding-block-start: var(--spacer-3);
        }
    }

    &__total-price-wrapper {
        margin-block-start: var(--spacer-4);
        padding-block-start: var(--spacer-3);
    }

    &__total {
        text-transform: uppercase;
        font-size: 1.25rem;
        font-weight: var(--fw-bold);
        line-height: 1.2;
    }

    &__total-price {
        margin-inline-start: auto;
        font-weight: var(--fw-bold);
        color: var(--c-black);
        font-size: 1.25rem;
    }

    &__buttons-wrapper {
        display: flex;
        flex-direction: column;
        gap: var(--spacer-3);
        margin-block-start: var(--spacer-5);
        margin-block-end: var(--spacer-3);
    }

    &__button {
        width: 100%;

        &--is-outline {
            @extend %button--is-outline;

            color: var(--c-black);
        }
    }
}
