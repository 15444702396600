@use "src/styles" as *;

$component: "newsletter-call-to-action";

.#{$component} {
    --newsletter-cta-image: none;

    --c-newsletter-cta-text: var(--c-white);
    --c-newsletter-cta-background: var(--c-green-dark);
    --c-newsletter-cta-store-locator-background: var(--c-white);

    color: var(--c-newsletter-cta-text);
    background-color: var(--c-newsletter-cta-background);

    &--image-is-background &__end-wrapper {
        background: var(--newsletter-cta-image);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    &--image-is-background-pattern &__end-wrapper {
        background: var(--newsletter-cta-image);
        background-repeat: repeat;
        background-position: left top;
        background-size: auto;
    }

    &__wrapper {
        display: flex;
        flex-direction: column;

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
        }

        &--is-reversed {

            @include for-size(tablet-portrait-up) {
                flex-direction: row-reverse;
            }

            .#{$component}__store-locator {

                @include for-size(tablet-portrait-up) {
                    padding-inline: var(--spacer-8) 0;
                }
            }

            .#{$component}__form-wrapper {

                @include for-size(tablet-portrait-up) {
                    padding-inline: 0 var(--spacer-8);
                }
            }
        }
    }

    &__start-wrapper,
    &__end-wrapper {
        @extend %flex-align-center;

        width: 100%;
    }

    &__start-wrapper {

        &--has-store-locator {
            background-color: var(--c-newsletter-cta-store-locator-background);
        }
    }

    &__end-wrapper {
        background-color: var(--c-newsletter-cta-background);

        &--is-only-wrapper {
            padding-block: var(--spacer-6);

            .#{$component}__inner-wrapper {
                margin-inline: auto;
            }

            .#{$component}__form-wrapper {
                padding-inline: unset;
            }
        }

        .#{$component}__inner-wrapper {
            padding-block: var(--spacer-6);

            @include for-size(tablet-portrait-up) {
                padding-block: var(--spacer-5);
            }
        }
    }

    &__inner-wrapper {
        @extend %flex-align-center;

        height: 100%;
        padding-block: var(--spacer-5);
    }

    &__store-locator {
        width: 100%;
        color: var(--c-black);

        @include for-size(tablet-portrait-up) {
            padding-inline: 0 var(--wrapper-padding);
        }

        @include for-size(tablet-landscape-up) {
            padding-inline: 0 var(--spacer-8);
        }
    }

    &__picture {
        width: 100%;
        height: 100%;
    }

    &__image {
        object-fit: cover;
    }

    &__form-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: var(--spacer-4);
        text-align: center;
        width: 100%;

        @include for-size(tablet-portrait-up) {
            padding-inline: var(--wrapper-padding) 0;
        }

        @include for-size(tablet-landscape-up) {
            padding-inline: var(--spacer-8) 0;
        }
    }

    &__form-title {

        &--is-large {
            font-size: 2.5rem;

            @include for-size(tablet-portrait-up) {
                font-size: 3rem;
            }
        }
    }

    &__form-description {
        text-wrap: balance;
    }

    &__form {
        width: 100%;
    }
}
