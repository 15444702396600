@use "src/styles" as *;

.brand-navigation {

    &__list {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-block-start: var(--spacer-6);
    }

    &__navigation-index-button,
    &__section-heading {
        padding-block: 0;
        padding-inline: var(--spacer-2);
        font-size: 1.75rem;
        font-weight: var(--fw-bold);
    }

    &__navigation-index-button {
        @extend %button--no-background;

        color: var(--c-green-dark);

        &:disabled {
            color: var(--c-green);
            background: none;
        }
    }

    &__section-wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: var(--spacer-4);
        margin-block-start: var(--spacer-6);

        @include for-size(tablet-portrait-up) {
            grid-template-columns: repeat(4, 1fr);
        }

        @include for-size(tablet-landscape-up) {
            grid-template-columns: repeat(5, 1fr);
        }
    }

    &__section {
        padding-block-start: var(--spacer-4);
        border-block-end: 1px solid var(--c-green-medium);
    }

    &__section-heading {
        color: var(--c-green-medium);
    }

    &__section-list {
        margin: 0;
        padding: 0;
        padding-block-end: var(--spacer-2);
        list-style: none;
    }

    &__section-list-item {
        position: relative;
        transition: background-color .1s ease-in-out;

        &:hover,
        &:focus-within {
            cursor: pointer;
            background-color: var(--c-white);
            box-shadow: var(--box-shadow);
        }
    }

    &__brand-link {
        display: block;
        width: 100%;
        padding: var(--spacer-2);
        font-size: .875rem;

        &::after {
            @extend %area-extend;

            content: "";
        }
    }
}
