.product-labels {

    &__label {
        display: flex;
        align-items: center;
        width: fit-content;
        font-size: .875rem;
        font-weight: var(--fw-regular);

        &--is-outlined {
            line-height: 1.5rem;
            border-block: 1px solid var(--c-black);
        }

        &--is-online-only {
            align-items: center;
            padding-inline: var(--spacer-2);
            color: var(--c-white);
            background-color: var(--c-green-dark);
        }

        &--has-discount {
            padding-block: var(--spacer-1);
            padding-inline: var(--spacer-2);
            color: var(--c-white);
            background-color: var(--c-rust);

            &::after {
                content: "%";
            }

            &::before {
                content: "-";
            }
        }

        &--is-bestseller,
        &--is-online-only {
            display: none; // TODO: Enable this when data is available (L5W-389)
        }
    }
}
