@use "src/styles" as *;

.product-detail {
    display: flex;
    flex-direction: column;
    gap: var(--spacer-4);

    @include for-size(tablet-portrait-up) {
        flex-direction: row;
        align-items: flex-start;
    }

    &__media-presentation {
        flex: 2;
    }

    &__sidebar {
        width: 100%;
        flex: 1;
        position: sticky;
        top: calc(var(--menu-height) + var(--submenu-height));
        align-self: flex-start;
    }
}
