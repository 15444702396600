@use "src/styles" as *;

$component: "content-column";

.#{$component} {

    &__title-html,
    &__paragraph-html {
        @extend %html-format;
    }

    &__paragraph-html {
        margin-block-start: var(--spacer-5);

        & + .#{$component}__image {
            margin-block-start: var(--spacer-5);
        }
    }

    h6 {
        font-size: 1.125rem;
        font-weight: var(--fw-bold);
        letter-spacing: 0;
    }

    &__image {
        width: 100%;
    }
}
