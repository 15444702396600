@use "src/styles" as *;

.footer {
    background-color: var(--c-background);

    &__wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: var(--spacer-4);
        row-gap: var(--spacer-5);
        padding-block: var(--spacer-5);

        @include for-size(tablet-portrait-up) {
            padding-block: var(--spacer-6);
        }

        @include for-size(tablet-landscape-up) {
            grid-template-columns: repeat(4, 1fr);
        }
    }

    &__nav {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 12rem;

        @include for-size(tablet-portrait-up) {
            flex: 1;
            width: 16rem;
        }
    }

    &__nav-title {
        @extend %heading-7;
    }

    &__nav-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: var(--spacer-3);
        margin-block-start: var(--spacer-3);
    }

    &__nav-item {
        width: 100%;
        font-weight: var(--fw-regular);
        line-height: 1.5;

        &--with-icon {
            width: initial;
        }
    }

    &__nav-link {
        display: block;
        width: fit-content;
        color: currentColor;
        text-decoration: none;
    }

    &__nav-link-icon {
        width: 1rem;
        height: 1.5rem;
    }

    &__cookie-settings-button {
        @extend %button--link;

        justify-self: flex-end;
        font-weight: var(--fw-regular);
        letter-spacing: 0;
        color: var(--c-black);
    }
}
