@use "src/styles" as *;

.store-detail-header-skeletons {
    display: grid;
    grid-template-columns: 1fr;
    border-block: 1px solid var(--c-green-medium);

    @include for-size(laptop-up) {
        grid-template-columns: 1fr 1fr;
    }

    &__image {
        @extend %skeleton-fade;

        width: 100%;
        grid-row: 2;
        background-color: var(--c-green-25);

        @include for-size(laptop-up) {
            grid-row: unset;
            grid-column-start: 1;
            height: 100%;
            max-height: calc(100vh - 9.375rem);
        }
    }

    &__info-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 43.75rem;
        padding: var(--spacer-5);

        @include for-size(laptop-up) {
            padding: var(--spacer-7) var(--spacer-5);
        }
    }

    &__title {
        font-size: 2rem;
    }

    &__address {
        max-width: 24rem;
        margin-block-start: var(--spacer-4);
    }

    &__address-line {

        & + & {
            margin-block-start: var(--spacer-2);
        }
    }

    &__availability {
        margin-block-start: var(--spacer-5);
    }

    &__opening-hours-wrapper {
        margin-block-start: var(--spacer-5);

        @include for-size(laptop-up) {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }
    }

    &__opening-hours-line {

        & + & {
            margin-block-start: var(--spacer-2);
        }
    }
}
