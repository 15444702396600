@use "../helpers" as *;

%label {
    font-size: 1rem;
    font-weight: var(--fw-bold);
    line-height: 1.375;
    color: currentColor;
}

%input-label {
    @extend %label;

    font-size: 1rem;
    font-weight: var(--fw-regular);
    line-height: 1.5;
}

%heading {
    font-family: var(--f-display);
    font-weight: var(--fw-bold);
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    hyphens: auto;
    word-break: break-word;
    color: currentColor;

    em, i {
        font-family: var(--f-script);
        font-weight: var(--fw-regular);
        font-size: 1.25em;
        text-transform: uppercase;
    }
}

%heading-1 {
    @extend %heading;

    font-size: 3rem;

    @include for-size(tablet-portrait-up) {
        font-size: 6rem;
    }
}

%heading-2 {
    @extend %heading;

    font-size: clamp(2rem, 9vw, 3rem);

    @include for-size(tablet-portrait-up) {
        font-size: clamp(2rem, 4.5vw, 4.5rem);
    }
}

%heading-3,
%heading-4 {
    @extend %heading;

    font-size: 2rem;
}

%heading-3 {

    @include for-size(tablet-portrait-up) {
        font-size: 3.5rem;
        letter-spacing: 1.12px;
    }
}

%heading-4 {

    @include for-size(tablet-portrait-up) {
        font-size: 3rem;
    }
}

%heading-5 {
    @extend %heading;

    font-size: 1.75rem;
    line-height: 1.14;
}

%heading-6 {
    @extend %heading;

    font-family: var(--f-primary);
    font-weight: var(--fw-regular);
    letter-spacing: .48px;
    font-size: 1.5rem;
    line-height: 1.33;
}

%heading-7 {
    @extend %heading;

    font-family: var(--f-primary);
    font-size: 1rem;
    line-height: 1.5;
}
