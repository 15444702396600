@use "src/styles" as *;

.account-dashboard-tab {

    &__recent-orders,
    &__configuration-section {
        margin-block-start: var(--spacer-5);
        padding-inline: var(--spacer-5);
    }

    &__heading {
        @extend %heading-6;
    }

    &__tab-list {
        margin-block: var(--spacer-5) var(--spacer-4);
        position: relative;

        &::after {
            content: "";
            position: absolute;
            z-index: 10;
            inset-block-end: 0;
            inset-inline-start: 0;
            width: 100%;
            height: 2px;
            background-color: var(--c-green-75);
        }
    }

    &__tab-item-label {
        font-family: var(--f-primary);
        font-weight: var(--fw-regular);
        font-size: 1.125rem;
        text-transform: initial;
        color: var(--c-black);
    }
}
