.search-page {

    &__pages-list {
        margin-block-start: var(--spacer-5);
    }

    &__block-renderer {

        &--is-hidden {
            display: none;
            visibility: hidden;
        }
    }

    &__results-wrapper {

        &--is-hidden {
            display: none;
            visibility: hidden;
        }
    }
}
