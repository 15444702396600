@use "src/styles" as *;

.address-card {
    display: flex;
    justify-content: space-between;
    height: fit-content;
    padding-block: var(--spacer-3);
    padding-inline: var(--spacer-2);
    background-color: var(--c-background);

    @include for-size(laptop-up) {
        padding-inline: var(--spacer-4);
    }

    &__type {
        display: block;
        font-size: .75rem;
        margin-block-end: var(--spacer-2);
        text-transform: uppercase;
    }

    &__button-wrapper {
        border-inline-start: 1px solid var(--c-green-50);
    }

    &__button {
        @extend %button--no-background;

        padding: var(--spacer-2) 0;
        margin: 0 var(--spacer-2);
        color: var(--c-black);
        font-weight: var(--fw-regular);

        &:disabled {
            color: var(--c-grey-50);
            background-color: unset;
        }

        @include for-size(laptop-up) {
            padding: var(--spacer-2) var(--spacer-3);
        }
    }

    &__button-label {
        gap: var(--spacer-2);
    }

    &__button-icon {
        color: var(--c-green);

        &--filled {
            fill: var(--c-green);
            stroke: var(--c-green);
        }
    }
}
