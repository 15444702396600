@use "src/styles" as *;

.account-navigation-sidebar {

    &__list {
        padding-block-start: var(--spacer-5);
        padding-block-end: var(--spacer-3);
        border-bottom: 1px solid var(--c-green-25);
        display: flex;
        flex-direction: column;
        gap: var(--spacer-3);
    }

    &__item,
    &__logout-button {
        @extend %button--no-background;

        display: flex;
        justify-content: flex-start;
        width: 100%;
        min-width: fit-content;
        font-weight: var(--fw-regular);
        color: var(--c-black);
    }

    &__link {

        &:hover::after,
        &:focus::after {
            opacity: 1;
        }
    }

    &__logout-button-label {
        gap: var(--spacer-2);
    }

    &__logout-button-icon {
        color: var(--c-green-medium);
        width: 1.25rem;
        height: 1.25rem;
        transition: color .15s ease-in-out;
    }

    &__logout-button {
        margin-block-start: var(--spacer-3);
        padding: var(--spacer-2);
        border-radius: .5rem;
    }
}
