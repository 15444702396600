@use "src/styles" as *;

.payment-methods {

    &__header {
        @extend %heading-7;

        width: 100%;
        margin-block-end: var(--spacer-3);
        padding-block: var(--spacer-2);
        font-size: .75rem;
        font-weight: var(--fw-medium);
        text-transform: uppercase;
        color: var(--c-green-75);
        border-block-end: 1px solid var(--c-green-50);

        @include for-size(tablet-portrait-up) {
            margin-block-end: var(--spacer-5);
        }
    }

    &__methods-wrapper {
        display: flex;
        justify-content: center;
        gap: var(--spacer-4);
    }

    &__picture {
        width: 4rem;
        height: 3rem;
        padding: var(--spacer-2);
        border: 1px solid var(--c-grey-light);
        border-radius: .25rem;

        @include for-size(tablet-portrait-up) {
            width: 6rem;
            height: 4rem;
        }
    }
}
