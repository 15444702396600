.account-labelled-value {

    &__label {
        font-family: var(--f-primary);
        font-size: 1rem;
        font-weight: var(--fw-light);
        color: var(--c-green-dark);
    }

    &__value {
        margin-block-start: var(--spacer-1);
    }
}
