@use "src/styles" as *;

.spaces-overview-renderer {

    &__list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        row-gap: var(--spacer-6);
        column-gap: var(--spacer-4);
        margin-block-start: var(--spacer-6);

        @include for-size(tablet-landscape-up) {
            grid-template-columns: repeat(3, 1fr);
            row-gap: var(--spacer-5);
        }
    }
}
