@use "src/styles" as *;

.search-suggestions {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: var(--spacer-5);

    @include for-size(laptop-up) {
        flex-direction: row;
        gap: var(--spacer-6);
    }

    &__page-suggestions {

        @include for-size(laptop-up) {
            min-width: 12rem;
        }
    }
}
