@use "src/styles" as *;

.product-detail-renderer {

    &__detail-wrapper {
        max-width: unset;
        margin-block-start: var(--spacer-5);
        padding-inline: unset;

        @include for-size(tablet-portrait-up) {
            max-width: var(--wrapper-width);
            padding-inline: var(--wrapper-padding);
        }
    }

    &__information-wrapper {
        max-width: unset;
        margin-block-start: var(--spacer-4);
        padding-inline: unset;

        @include for-size(tablet-portrait-up) {
            max-width: var(--wrapper-width);
            margin-block-start: var(--spacer-8);
            padding-inline: var(--wrapper-padding);
        }
    }

    &__brand-section {
        margin-block-start: var(--spacer-5);
    }

    &__related-products-slider {
        margin-block-start: var(--spacer-8);
        padding-block-end: var(--spacer-8);
    }

    &__related-products-product {
        height: 100%;
    }

    &__related-products-picture-wrapper {
        aspect-ratio: 1;
    }
}
