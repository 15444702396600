@use "src/styles" as *;

$component: "navigation-link";

.#{$component} {
    padding: 0;

    &--is-lower-case &__button,
    &--is-lower-case &__link {
        font-family: var(--f-primary);
        font-size: 1.125rem;
        font-weight: var(--fw-regular);
        line-height: 1.33;
    }

    &--is-expanded {
        color: var(--c-green-dark);

        .#{$component}__chevron {
            scale: -1 1;
        }
    }

    &--is-active,
    &--is-active#{&}--is-sale {
        color: var(--c-green-dark);
    }

    &--is-sale {
        color: var(--c-rust);
    }

    &--has-light-weight &__link {
        font-family: var(--f-primary);
        font-size: 1.375rem;
        font-weight: var(--fw-regular);
        line-height: 1.45;
        letter-spacing: .44px;
    }

    &__button,
    &__link {
        display: flex;
        justify-content: space-between;
        gap: var(--spacer-3);
        width: 100%;
        padding: 0;
        padding-block: var(--spacer-2);
        font-family: var(--f-display);
        font-size: 1.75rem;
        font-weight: var(--fw-bold);
        line-height: 1;
        color: currentColor;
    }

    &__button {
        @extend %button--no-background;

        &::after {
            content: unset;
        }
    }

    &__link {

        &--is-active {
            color: var(--c-green-dark);
        }
    }

    &__label-wrapper {
        display: flex;
        gap: var(--spacer-3);
        width: 100%;
    }

    &__icon {
        width: 2rem;
    }

    &__label {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        &:hover,
        &:focus {
            color: var(--c-green-dark);
        }
    }

    &__chevron {
        display: block;
        height: 1.5rem;
        color: var(--c-black);
        transition: scale .1s ease;
    }
}
