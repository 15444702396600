@use "src/styles" as *;

.top-navbar {
    color: var(--c-green-dark);
    background-color: var(--c-white);

    &__desktop-wrapper {
        display: none;
        font-size: .875rem;

        @include for-size(tablet-landscape-up) {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    &__usps-wrapper,
    &__links-wrapper {
        flex: 1;
    }

    &__links-wrapper {

        & > * {
            display: flex;
            gap: var(--spacer-4);
            padding-inline-end: var(--spacer-4);
            border-inline-end: 1px solid rgba(var(--c-green-medium-rgb), .4);

            &:last-child {
                padding-inline-end: unset;
                border-inline-end: unset;
            }
        }
    }

    &__text-carousel {
        position: relative;
        overflow: clip;
        width: 100%;
        height: 2rem;
        font-weight: var(--fw-regular);
    }

    &__carousel-item {
        display: flex;
        align-items: center;
        position: absolute;
        top: var(--spacer-2);
        z-index: 0;
        transition: translate .5s var(--ease-out-strong), opacity .5s var(--ease-out-strong);
        translate: 0 100%;
        opacity: 0;

        &--is-active {
            z-index: 10;
            translate: 0 0;
            opacity: 1;
        }

        &--is-leaving {
            z-index: 0;
            translate: 0 -100%;
            opacity: 0;
        }
    }

    &__links-wrapper {
        display: flex;
        gap: var(--spacer-4);
        justify-content: flex-end;
        margin: 0;
        padding: 0;
        font-weight: var(--fw-medium);
    }

    &__link-item {

        &:not(:last-of-type) {
            padding-inline-end: var(--spacer-4);
            border-inline-end: 1px solid rgba(var(--c-green-medium-rgb), .4);
        }
    }
}
