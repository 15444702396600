@use "src/styles" as *;

.giftcard-balance-inputs {

    &__list {
        display: flex;
        gap: var(--spacer-1);
        row-gap: var(--spacer-3);
        margin-block-end: 1rem;

        @include for-size(tablet-portrait-up) {
            gap: var(--spacer-4);
        }
    }

    &__legend {
        margin-inline-end: auto;
        font-weight: var(--fw-regular);
    }

    &__card-number-input {
        width: 3rem;
        font-size: .75rem;

        @include for-size(tablet-portrait-up) {
            width: 100%;
            font-size: 1rem;
        }
    }

    &__card-number-field {
        padding-inline: var(--spacer-2);

        @include for-size(tablet-portrait-up) {
            padding-inline: var(--spacer-3);
        }
    }

    &__pin-code-input {
        width: 100%;
        margin-block-start: var(--spacer-4);
    }
}
