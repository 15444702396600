@use "src/styles" as *;

.giftcard-balance-form {

    &__header {
        display: flex;
        justify-content: space-between;
        margin-block-end: var(--spacer-5);
    }

    &__title {
        @extend %heading-6;
    }

    &__card-wrapper {
        display: flex;
        flex-direction: column;
        padding: var(--spacer-4);
        border-radius: 1.5rem;
        background-color: var(--c-background);

        @include for-size(tablet-portrait-up) {
            max-width: 36rem;
            padding: var(--spacer-6);
        }
    }

    &__recaptcha-disclaimer {
        margin-block-start: var(--spacer-3);
    }

    &__giftcard-balance {
        font-size: 1.25rem;
        font-weight: var(--fw-medium);
        margin-block-start: var(--spacer-4);
    }

    &__error-label,
    &__submit-button {
        margin-block-start: var(--spacer-4);
    }
}
