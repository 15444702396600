@use "src/styles" as *;

.coupon-code-form {

    &__field-wrapper {
        display: flex;
        position: relative;
    }

    &__field {
        border-start-end-radius: 0;
        border-end-end-radius: 0;
        border-inline-end: none;

        @include for-size(tablet-portrait-up) {
            min-width: 16rem;
        }
    }

    &__visibility-button {
        @extend %button--is-outline;

        color: var(--c-green);
        padding-block: var(--spacer-2);
        padding-inline: var(--spacer-3);
        border-start-start-radius: 0;
        border-end-start-radius: 0;
        border: var(--input-border);
    }

    &__visibility-icon {
        @include square(1.5rem);
    }

    &__recaptcha-disclaimer {
        margin-block-start: var(--spacer-3);
    }

    &__error-label {
        margin-block-start: var(--spacer-4);
    }
}
