.store-detail-map {
    height: 40rem;
    max-height: 85dvh;
    overflow: clip;

    &__map {
        width: 100%;
        aspect-ratio: 1;
    }
}
