@use "src/styles" as *;

.shopping-cart-count {
    position: relative;

    &__icon-button {
        @extend %button--no-background;

        padding: var(--spacer-2);
        color: var(--c-black);
    }

    &__icon-accent {
        fill: var(--c-green);
    }

    &__amount {
        @extend %flex-align-center;

        position: absolute;
        z-index: 20;
        left: var(--spacer-3);
        top: 50%;
        max-height: 1.125rem;
        min-width: 1.25rem;
        padding-block: var(--spacer-1);
        padding-inline: var(--spacer-2);
        font-size: .875rem;
        font-weight: var(--fw-medium);
        color: var(--c-white);
        line-height: normal;
        pointer-events: none;
        border-radius: 3.75rem;
        background-color: var(--c-green-dark);
        translate: 25% -60%;
    }
}
