@use "src/styles" as *;

.product-list {

    &--has-backdrop {
        position: relative;

        &::after {
            @extend %area-extend;

            content: "";
            z-index: 10;
            background: var(--c-shade);
        }
    }

    &__heading {
        @extend %heading-4;

        margin-block-end: var(--spacer-5);
        color: var(--c-green-dark);
    }

    &__filter-section {
        position: relative;
        z-index: 1;
        background: var(--c-background);
    }

    &__grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: var(--spacer-4);
        row-gap: var(--spacer-5);
        margin-block-start: var(--spacer-4);

        @include for-size(tiny-screen-only) {
            grid-template-columns: 1fr;
        }

        @include for-size(tablet-landscape-up) {
            grid-template-columns: repeat(4, 1fr);
            row-gap: var(--spacer-6);
        }
    }

    &__call-to-action {
        grid-column: span 2;

        @include for-size(tiny-screen-only) {
            grid-column: span 1;
        }

        @include for-size(tablet-portrait-up) {
            max-height: 30rem;
        }
    }

    &__no-results-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: var(--spacer-5);
    }

    &__no-results {
        @extend %heading-5;

        color: var(--c-green-medium);
        text-align: center;
    }

    &__slider {
        margin-block-start: var(--spacer-4);

        @include for-size(tablet-portrait-up) {
            margin-block-start: var(--spacer-5);
        }
    }

    &__pagination-section {
        margin-block-start: var(--spacer-6);
        padding-block-start: var(--spacer-3);
        background-color: var(--c-background);
    }

    &__pagination {
        width: fit-content;
        margin-inline: auto;
    }
}
