@use "src/styles" as *;

.slider {
    --slider-padding: calc(var(--wrapper-padding) * 2);
    --slider-space-around: calc(100vw - var(--scrollbar-width) - var(--wrapper-width) + var(--slider-padding));

    --slider-inset-min: var(--wrapper-padding);
    --slider-inset-max: calc(var(--slider-space-around) / 2);
    --slider-inset: max(var(--slider-inset-min), var(--slider-inset-max));

    --slider-columns: var(--slider-columns-mobile);
    --slider-column-width: calc(((100% - var(--slider-inset)) - var(--wrapper-padding)) / var(--slider-columns));

    position: relative;
    width: 100%;
    overflow: clip;

    @include for-size(tablet-portrait-up) {
        --slider-columns: var(--slider-columns-tablet-portrait);
    }

    @include for-size(tablet-landscape-up) {
        --slider-columns: var(--slider-columns-tablet-landscape);
    }

    @include for-size(desktop-up) {
        --slider-columns: var(--slider-columns-desktop);
    }

    &__navigation-wrapper {
        @extend %area-extend;

        display: flex;
        justify-content: space-between;
        z-index: 20;
        pointer-events: none;
    }

    &__navigation-button {
        min-width: calc(var(--slider-column-width) / 3);
        pointer-events: all;

        @include for-size(tablet-portrait-up) {
            min-width: calc(var(--slider-column-width) / 2);
        }

        @include touch-screen {
            display: none;
            visibility: hidden;
        }
    }

    &__list {
        display: flex;
        align-items: stretch;
        gap: var(--spacer-3);
        width: calc(100% + (var(--wrapper-padding) * 2));
        margin-inline-start: negative(var(--wrapper-padding));
        padding-inline: 50%;
        overflow-x: auto;
        overflow-y: hidden;
        scroll-snap-type: x mandatory;
        scroll-snap-align: center;

        @include for-size(tablet-portrait-up) {
            gap: unset;
            width: calc(100% - var(--slider-inset));
            height: fit-content;
            margin-inline-start: unset;
            padding: unset;
            overflow: unset;
            transition: transform .75s var(--ease-out-strong);
            transform: translateX(calc((#{var(--slider-inset)} + var(--slider-scroll-progress) * -1)));
        }

        @include prefers-reduced-motion {
            transition: none;
        }

        &--is-resizing {
            transition: none;
        }
    }

    &__item {
        position: relative;
        min-width: calc((100vw / var(--slider-columns)) - (var(--wrapper-padding) * 2));
        min-height: 100%;
        scroll-snap-align: center;

        @include for-size(tablet-portrait-up) {
            min-width: var(--slider-column-width);
            max-width: var(--slider-column-width);
            padding-inline-end: var(--spacer-4);
        }
    }
}
