@use "src/styles" as *;

.search-results-skeletons {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: var(--spacer-5);
    width: 100%;

    @include for-size(laptop-up) {
        flex-direction: row;
        gap: var(--spacer-6);
    }

    &__product-results,
    &__page-results {
        width: 100%;
        padding-block-start: var(--spacer-4);
    }

    &__page-results {

        @include for-size(laptop-up) {
            width: unset;
            min-width: 16rem;
        }
    }

    &__label {
        width: 10rem;
        height: .875rem;
    }

    &__list {
        display: flex;
        flex-direction: column;
        margin-block-start: var(--spacer-4);
    }

    &__item {
        padding: var(--spacer-3);
        border-block-end: 1px solid var(--c-green-75);
    }
}
