@use "src/styles" as *;

.blog-overview {

    &__title {
        @extend %heading-3;

        margin-block-end: var(--spacer-6);
        color: var(--c-green-dark);

        @include for-size(tablet-portrait-up) {
            margin-block-end: var(--spacer-7);
        }
    }

    &__start-set {
        margin-block-start: var(--spacer-5);

        @include for-size(tablet-portrait-up) {
            margin-block-start: var(--spacer-7);
        }
    }

    &__highlighted-blog-post {
        --wrapper-padding: 0;

        margin-block-start: var(--spacer-5);
        padding-block: var(--spacer-5);
        border-block: 1px solid var(--c-black);

        @include for-size(tablet-portrait-up) {
            margin-block-start: var(--spacer-7);
            padding-block: var(--spacer-6);
        }
    }

    &__end-set {
        margin-block-start: var(--spacer-5);

        @include for-size(tablet-portrait-up) {
            margin-block-start: var(--spacer-7);
        }
    }

    &__pagination {
        justify-content: center;
        margin-block-start: var(--spacer-5);
    }
}
