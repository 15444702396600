.page-search-results {
    --wrapper-width: 40rem;

    display: flex;
    align-items: center;
    justify-content: center;

    &__list {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-inline: auto;
        padding: var(--spacer-2);
    }
}
