@use "src/styles" as *;

$component: "link-button";

.#{$component} {
    @extend %button;

    width: fit-content;
    text-decoration: none;

    &:not(&--is-disabled) {

        &:hover,
        &:focus {

            &::after {
                opacity: 1;
            }
        }
    }

    &--is-disabled {
        color: var(--c-black);
        pointer-events: none;
        background-color: var(--c-shade);
    }

    &--is-small {
        --button-label-offset: calc(-100% - var(--spacer-3));

        padding-block: var(--spacer-2);
        padding-inline: var(--spacer-3);
    }

    &--has-animation {
        overflow: clip;

        &:hover .#{$component}__label,
        &:focus .#{$component}__label {
            translate: 0 negative(var(--button-label-offset));
        }

        .#{$component}__label {
            transition: translate calc(.3s * var(--transition-multiplier)) ease;
            translate: 0 0;

            &::after {
                @extend %area-extend;

                content: attr(data-text);
                translate: 0 var(--button-label-offset);
            }
        }
    }

    &__label {
        position: relative;
        z-index: 20;
    }
}
