.input-label-wrapper {
    --input-border: 1px solid currentColor;

    width: fit-content;

    &--has-error {
        --input-border: 1px solid var(--c-error);
    }

    &__label {
        margin-block-end: var(--spacer-1);
    }

    &__error-label {
        margin-block-start: var(--spacer-1);
        padding-inline: var(--spacer-3);
    }
}
