@forward "typography";

%html-format {

    & > * + * {
        margin-block-start: var(--spacer-5);
    }

    h1 {
        @extend %heading-1;
    }

    h2 {
        @extend %heading-2;
    }

    h3 {
        @extend %heading-3;
    }

    h4 {
        @extend %heading-4;
    }

    h5 {
        @extend %heading-5;
    }

    h6 {
        @extend %heading-6;
    }

    ul {
        padding-inline-start: var(--spacer-4);
    }

    ol {
        padding-inline-start: var(--spacer-4);
    }

    ol li,
    ul li {
        list-style-type: initial;
    }

    a {
        color: currentColor;
        text-decoration: underline;
    }

    blockquote,
    q {
        font-family: var(--f-elegant);
        font-weight: var(--fw-regular);
        font-size: 2rem;
        font-style: italic;
        line-height: 1.25;
    }

    img {
        max-width: 100%;
    }

    figure {
        margin-inline: 0;
        margin-block-end: 0;
    }

    figcaption {
        caption-side: bottom;
        margin-block-start: var(--spacer-2);
    }

    table {
        border-collapse: collapse;

        th, td {
            width: 11.25rem;
            padding-block-start: var(--spacer-2);
            border-block-end: 1px solid var(--c-green);
        }
    }
}
