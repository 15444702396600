@use "src/styles" as *;

$component: "featured-products-alternating-sizes";

.#{$component} {

    &__title {
        @extend %heading-3;

        color: var(--c-green-dark);
    }

    &__products-wrapper {
        display: flex;
        flex-direction: column;
        gap: var(--spacer-4);
        margin-block-start: var(--spacer-5);

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
        }
    }

    &__main-wrapper {
        width: 100%;

        @include for-size(tablet-portrait-up) {
            display: flex;
        }
    }

    &__rest-wrapper {
        width: 100%;

        @include for-size(tablet-portrait-up) {
            order: -1;
        }
    }

    &__rest-list {
        display: flex;
        flex-wrap: wrap;
        gap: var(--spacer-4);
    }

    &__rest-item {
        width: calc((100% - var(--spacer-4)) / 2);

        &--is-wide {
            width: 100%;

            .#{$component}__picture {
                aspect-ratio: 2 / 1;
            }
        }
    }

    &__product {
        width: 100%;
    }

    &__picture-wrapper {
        height: 100%;
        min-height: 22rem;
    }

    &__picture {
        aspect-ratio: 1;
    }

    &__footer {
        margin-block-start: var(--spacer-5);

        @include for-size(tablet-portrait-up) {
            margin-block-start: var(--spacer-4);
        }
    }
}
