@use "src/styles" as *;

.payment-step {
    display: flex;
    flex-direction: column;
    padding: var(--spacer-5);
    background-color: var(--c-white);

    &__heading {
        @extend %heading-6;
    }

    &__sub-heading {
        margin-block-start: var(--spacer-2);
        margin-block-end: var(--spacer-5);
    }

    &__addresses {
        margin-block-end: var(--spacer-4);
    }

    &__addresses-heading,
    &__payment-methods-heading {
        @extend %heading-7;

        width: 100%;
        border-block-end: 1px solid var(--c-green-50);
        margin-block-end: var(--spacer-4);
        padding-block: var(--spacer-2);
        font-size: .75rem;
        font-weight: var(--fw-medium);
        text-transform: uppercase;
    }

    &__address {
        margin-block-end: var(--spacer-4);
    }

    &__textarea {
        width: 100%;
    }

    &__textarea-field {
        min-width: unset;
    }

    &__payment-methods-wrapper {
        margin-block-start: var(--spacer-4);
    }

    &__payment-methods {
        margin-block-end: var(--spacer-5);
        width: 100%;
    }
}
