@use "src/styles" as *;

.account-wishlist-tab {

    &__wishlist-section {
        margin-block-start: var(--spacer-5);
    }

    &__wishlist-title {
        @extend %heading-6;

        font-family: var(--f-primary);
    }

    &__wishlist {
        margin-block-start: var(--spacer-4);
    }
}
