@use "src/styles" as *;

$component: "footer-bottom";

.#{$component} {
    background-color: var(--c-white);

    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: var(--spacer-3);
        padding-block: var(--spacer-3);

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
            gap: 0;
        }
    }

    &__content {
        display: flex;
        align-items: center;
        gap: var(--spacer-4);

        .#{$component}__link,
        .#{$component}__text {
            font-size: .875rem;
        }

        @include for-size(tablet-portrait-up) {
            flex: 1;
        }
    }

    &__content-left {

        @include for-size(tablet-portrait-up) {
            justify-content: flex-end;
            margin-inline-end: var(--spacer-6);
        }
    }

    &__logo {
        width: 1.75rem;
        height: 1.75rem;
    }

    &__content-right {
        font-weight: var(--fw-regular);

        @include for-size(tablet-portrait-up) {
            justify-content: flex-start;
            margin-inline-start: var(--spacer-6);
        }
    }
}
