@use "src/styles" as *;

// Shortcut mixin to remove automatic margin between two specific adjacent blocks
@mixin no-margin($block, $adjacentBlock) {
    &.#{$block} + &.#{$adjacentBlock} {
        margin-block-start: 0;

        @include for-size(tablet-portrait-up) {
            margin-block-start: 0;
        }
    }
}

.block-renderer {

    &__block {

        & + & {
            margin-block-start: var(--spacer-6);

            @include for-size(tablet-portrait-up) {
                margin-block-start: var(--spacer-7);
            }
        }

        @include no-margin(service-section, newsletter-call-to-action);
        @include no-margin(newsletter-call-to-action, service-section);
    }
}
