@use "src/styles" as *;

.carousel {
    position: relative;
    overflow-x: clip;

    &__title {
        @extend %heading-5;

        margin-block-end: var(--spacer-5);
    }

    &__navigation-wrapper {
        @extend %area-extend;

        display: flex;
        justify-content: space-between;
        z-index: 10;
    }

    &__navigation-button {
        width: calc(100% / 3);
    }

    &__column-list {
        display: grid;
        grid-template-columns: 1fr 16rem 1fr;
        gap: var(--spacer-4);
        width: calc(120vw - var(--scrollbar-width));
        translate: -10vw 0;

        @include for-size(tablet-portrait-up) {
            grid-template-columns: .64fr .8fr 1fr .8fr .64fr;
        }
    }

    &__column {
        height: clamp(10rem, 80dvh, 32rem);
        padding-block-end: 7rem;

        @include for-size(tablet-portrait-up) {
            height: clamp(10rem, 80dvh, 46rem);
        }

        &:first-of-type,
        &:last-of-type {
            display: none;
            visibility: hidden;
            padding-block-start: 6.25rem;

            @include for-size(tablet-portrait-up) {
                display: block;
                visibility: visible;
            }
        }

        &:nth-of-type(3) {
            padding-block-end: 0;
        }
    }
}
