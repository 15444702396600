@use "src/styles" as *;

$component: "wishlist-button";

.#{$component} {
    @extend %button--is-outline;

    --wishlist-button-size: 1.5rem;

    overflow: visible;
    color: var(--c-green-dark);

    &:enabled:hover,
    &:enabled:focus {

        .#{$component}__icon {
            scale: 1.2;

            @include prefers-reduced-motion {
                scale: 1;
            }
        }
    }

    &--is-active {

        &::after {
            opacity: 1;
        }

        .#{$component}__animation-wrapper {
            opacity: 1;
            transition-delay: .1s;
        }

        .#{$component}__icon {

            &--fill {
                scale: 1;
                opacity: 1;
            }

            &--animation {
                transition-duration: calc(1s * var(--transition-multiplier));
                opacity: 0;

                &:nth-child(1) {
                    translate: -1rem -2.25rem;
                    rotate: -25deg;
                }

                &:nth-child(2) {
                    translate: 0 -2.5rem;
                    rotate: -6deg;
                }

                &:nth-child(3) {
                    translate: .625rem -2.75rem;
                    rotate: 20deg;
                }
            }
        }
    }

    &__icon-wrapper {
        @include square(var(--wishlist-button-size));

        position: relative;
    }

    &__icon {
        height: var(--wishlist-button-size);

        &--main {
            transition: scale .1s ease-in;
        }

        &--fill {
            transition: scale .1s ease-in, opacity .1s ease;
            opacity: 0;
        }

        &--animation {
            transition: translate, rotate, opacity;
            transition-duration: 0s;
            transition-timing-function: ease;
            translate: 0 0;
            rotate: 0deg;

            &:nth-of-type(1) {
                transition-delay: .225s;
            }

            &:nth-of-type(2) {
                transition-delay: .1s;
            }

            &:nth-of-type(3) {
                transition-delay: .25s;
            }
        }
    }

    &__icon-path {
        fill: currentColor;
    }

    &__animation-wrapper {
        @include square(var(--wishlist-button-size));

        position: absolute;
        transition: opacity .1s ease;
        opacity: 0;
    }

    &__icon {
        position: absolute;
        inset-block-start: 0;
        inset-inline-start: 0;
        width: 1.5rem;
        height: 1.5rem;
        fill: var(--c-green-medium);
    }
}
