@use "src/styles" as *;

.desktop-main-navigation-column {

    &__group {
        position: relative;
        margin: 0;
        padding: 0;

        & + & {
            margin-block-start: var(--spacer-2);
            padding-block-start: var(--spacer-2);

            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: calc(100% - 2.5rem);
                height: 1px;
                background-color: var(--c-black);
            }
        }

        &:last-of-type {
            margin-block-end: var(--spacer-5);
        }
    }

    &__parent-link-button {
        @extend %button--no-background;

        margin-block-end: var(--spacer-5);
        padding-inline: 0;
        text-transform: uppercase;
        color: var(--c-green-medium);

        &::after {
            content: unset;
        }
    }

    &__parent-link-icon {
        color: var(--c-black);
    }
}
