@use "src/styles" as *;

$component: "checkbox";

.#{$component} {
    --checkbox-size: 1.25rem;

    &:hover,
    &:focus-within {

        .#{$component}__icon {
            color: var(--c-accent);
            scale: 1;
            opacity: 1;
        }
    }

    &--is-small {
        --checkbox-size: 1.25rem;

        .#{$component}__icon {
            width: 1rem;
        }
    }

    &--is-checked {

        &:hover,
        &:focus-within {

            .#{$component}__icon {
                color: var(--c-white);
            }
        }
    }

    &--is-disabled {
        color: var(--c-charcoal);
        cursor: not-allowed;

        .#{$component}__box {
            background-color: var(--c-shade);
        }

        .#{$component}__icon {
            display: none;
        }
    }

    &--has-error {
        .#{$component}__box {
            border-color: var(--c-error);
        }
    }

    &__label {
        display: flex;
        align-items: flex-start;
        width: fit-content;
        font-size: 1rem;
        font-weight: var(--fw-regular);
        cursor: pointer;
    }

    &__input {
        position: absolute;
        opacity: 0;

        &:checked ~ .#{$component}__box {

            &::after {
                scale: 1;

                @include prefers-reduced-motion {
                    opacity: 1;
                }
            }

            .#{$component}__icon {
                scale: 1;
                opacity: 1;
            }
        }
    }

    &__box {
        @extend %flex-align-center;
        @include square(var(--checkbox-size));

        position: relative;
        overflow: clip;
        border-radius: var(--border-radius);
        border: 1px solid var(--c-green-75);
        background-color: var(--c-white);
        translate: 0 1px;

        &::after {
            @include square(var(--checkbox-size));

            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 10;
            background-color: var(--c-accent);
            translate: -50% -50%;
            scale: 0;
            transition: scale .1s ease;

            @include prefers-reduced-motion {
                transition: opacity .1s ease;
                scale: 1;
                opacity: 0;
            }
        }
    }

    &__color-indicator {
        @include circle(1rem);

        align-self: center;
        margin-inline-start: var(--spacer-2);
        background: var(--c-swatch);

        &--has-border {
            border: 1px solid var(--c-green-light);
        }
    }

    &__icon {
        position: relative;
        z-index: 20;
        width: 1.25rem;
        color: var(--c-white);
        transition: scale calc(.1s * var(--transition-multiplier)) ease, opacity .1s ease;
        scale: 0;
        opacity: 0;
    }

    &__label-text {
        margin-inline-start: var(--spacer-2);

        a {
            text-decoration: underline;
        }
    }

    &__error-label {
        margin-block: var(--spacer-1);
        padding-inline: var(--spacer-3);
    }
}
