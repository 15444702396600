@use "src/styles" as *;

.pricing {
    display: flex;
    flex-direction: column;
    gap: var(--spacer-2);

    &__price-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__price {
        margin-left: auto;

        &--is-sale {
            color: var(--c-sale);
        }
    }

    &__tooltip-wrapper {
        @extend %flex-align-center;
    }

    &__tooltip-icon {
        height: 1.5rem;
        color: var(--c-error);
    }

    &__remove-discount-button {
        @extend %button--link;

        margin-inline-start: var(--spacer-2);
        font-weight: var(--fw-light);
        color: var(--c-black);

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }

    &__accordion {
        width: 100%;
        padding-inline-start: 0;
    }

    &__accordion-content {
        padding: var(--spacer-3) 0;
    }

    &__accordion-heading {
        width: fit-content;
        gap: var(--spacer-3);
        padding: unset;
        font-weight: unset;
        line-height: 1.125;
        color: var(--c-black);
    }

    &__price-label-total,
    &__price-total {
        font-family: var(--f-display);
        font-size: 2rem;
        font-weight: var(--fw-bold);
    }
}
