@use "src/styles" as *;

.product-detail-renderer-skeletons {

    &__sidebar {
        width: 100%;
        height: 100%;
    }

    &__breadcrumbs-inner-wrapper {
        max-width: 24rem;
    }

    &__details-wrapper {
        display: flex;
        flex-direction: column;
        gap: var(--spacer-4);
        margin-block-start: var(--spacer-5);

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
            align-items: flex-start;
        }
    }

    &__details-image-list {
        flex: 2 1;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: var(--spacer-4);

        @include for-size(tablet-portrait-up) {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    &__details-image {
        width: 100%;
        height: unset;
        aspect-ratio: 1;
    }

    &__details-sidebar {
        flex: 1;
        width: 100%;
        height: 32rem;
    }

    &__information-description {
        width: 100%;
        height: 40rem;
        margin-block-start: var(--spacer-5);
    }

    &__brand-description {
        width: 100%;
        height: 20rem;
        margin-block-start: var(--spacer-5);
    }
}
