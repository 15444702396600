@use "src/styles" as *;

$component: "highlighted-media";

.#{$component} {
    --c-highlighted-media-title: var(--c-green-dark);

    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: var(--spacer-6);

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
            justify-content: center;
            gap: var(--spacer-7);
        }

        &--is-reversed {

            @include for-size(tablet-portrait-up) {
                flex-direction: row-reverse;
            }
        }
    }

    &__media-wrapper {
        display: flex;
        align-items: center;
        max-height: 16.25rem;

        &:has(.#{$component}__picture) {
            overflow: hidden;
        }

        @include for-size(tablet-portrait-up) {
            width: 53.375rem;
            min-height: 37.75rem;
        }
    }

    &__picture,
    &__video {
        width: 100%;
        height: 100%;
    }

    &__image {
        object-fit: cover;
    }

    &__text-wrapper {
        width: 100%;
        height: 100%;

        @include for-size(tablet-portrait-up) {
            width: 27.625rem;
        }
    }

    &__title {
        font-weight: var(--fw-regular);
        font-size: 3.5rem;
        line-height: 1.14;
        text-transform: uppercase;
        color: var(--c-highlighted-media-title);

        &, h1, h2, h3, h4, h5, h6 {
            font-family: var(--f-script);
        }
    }

    &__paragraph-html {
        @extend %html-format;

        font-family: var(--f-elegant);
        font-weight: var(--fw-regular);
        font-size: 2rem;
        margin-block-start: var(--spacer-5);
    }

    &__button {
        margin-block-start: var(--spacer-5);

        @include for-size(tablet-portrait-up) {
            margin-block-start: var(--spacer-6);
        }
    }
}
