.reset-password-form {
    display: flex;
    flex-direction: column;
    gap: var(--spacer-3);

    &__heading {
        font-size: 1.75rem;
    }

    &__username-input {
        width: 100%;
    }

    &__submit-button {
        padding-block: var(--spacer-2);
    }

    &__error-label {
        margin-block-start: var(--spacer-4);
    }

    &__back-to-login-button {
        margin-block-start: var(--spacer-4);
        font-weight: var(--fw-regular);
        text-decoration: underline;
    }
}
