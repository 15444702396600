.payment-methods-list {

    &__list {
        width: 100%;
    }

    &__payment-method-item {
        width: 100%;
        padding: var(--spacer-2);
        margin-block: var(--spacer-2);
        border: 1px solid var(--c-green-75);

        &:first-of-type {
            padding-block-start: var(--spacer-2);
            margin-block-start: 0;
        }

        &:last-of-type {
            padding-block-end: var(--spacer-2);
        }
    }

    &__giftcard-input-wrapper {
        margin-block-start: var(--spacer-3);
    }

    &__giftcard-disclaimer {
        margin-block-start: var(--spacer-3);
        font-size: .875rem;
    }
}
