@mixin for-size($size) {
    @if $size == tiny-screen-only {
        @media (max-width: 400px) {
            @content;
        }
    } @else if $size == phone-only {
        @media (max-width: 768px) {
            @content;
        }
    } @else if $size == tablet-portrait-up {
        @media (min-width: 768px) {
            @content;
        }
    } @else if $size == tablet-landscape-up {
        @media (min-width: 1024px) {
            @content;
        }
    } @else if $size == laptop-up {
        @media (min-width: 1280px) {
            @content;
        }
    } @else if $size == desktop-up {
        @media (min-width: 1448px) {
            @content;
        }
    } @else if $size == big-desktop-up {
        @media (min-width: 1800px) {
            @content;
        }
    }
}

@mixin touch-screen {
    @media (hover: none) and (pointer: coarse) {
        @content;
    }
}

@mixin prefers-reduced-motion {
    @media (prefers-reduced-motion: reduce) {
        @content;
    }
}

@mixin prevent-body-scroll($selector: &) {
    @at-root body:has(&) {
        overflow: hidden;
    }
}

@mixin increase-clickable-area($size: .5rem) {
    border: $size solid transparent;
    margin: -$size;
}

@mixin square($size: 1rem) {
    width: $size;
    aspect-ratio: 1;
}

@mixin circle($size: 1rem) {
    @include square($size);

    border-radius: 50%;
}

@mixin rtl {
    @at-root [dir="rtl"] & {
        @content;
    }
}
