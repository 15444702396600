@use "src/styles" as *;

.registration-form-step-account-details {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;

    @include for-size(tablet-landscape-up) {
        flex-direction: row;
        gap: var(--spacer-5);
    }

    &__title {
        @extend %heading-6;

        font-size: 1.75rem;
        margin-block-end: var(--spacer-5);
    }

    &__header-image {

        @include for-size(tablet-landscape-up) {
            order: -1;
        }
    }

    &__header-picture {
        width: 100%;
    }

    &__column {
        flex: 1;
        display: flex;
        flex-direction: column-reverse;

        @include for-size(tablet-landscape-up) {
            flex-direction: column;
        }
    }

    &__info-block {

        & + & {
            margin-block-start: var(--spacer-5);
        }
    }

    &__form-section {
        display: flex;
        flex-direction: column;
        gap: var(--spacer-3);
    }

    &__content {
        flex: 1;
        padding: var(--spacer-5);
        background-color: var(--c-white);

        &--personal-data {
            padding-block: var(--spacer-7);
            padding-inline: var(--spacer-5);
        }

        &--account {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: var(--spacer-5);
        }
    }

    &__input-row {
        display: flex;
        flex-direction: column;
        gap: var(--spacer-3);

        @include for-size(laptop-up) {
            flex-direction: row;
        }
    }

    &__text-input {
        width: 100%;
    }

    &__password-input-field {
        flex: 1;
    }

    &__submit-button {
        width: 100%;
        margin-block-start: var(--spacer-5);
        padding-block: var(--spacer-2);
    }

    &__recaptcha-disclaimer {
        margin-block-start: var(--spacer-3);
    }

    &__error-label {
        margin-block-start: var(--spacer-4);
    }

    &__login-button {
        display: block;
        width: fit-content;
        margin-block-start: var(--spacer-4);
        font-weight: var(--fw-regular);
        text-decoration: underline;
    }
}
