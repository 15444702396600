@use "src/styles" as *;

.highlighted-filter {

    &__filter-drawer {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: var(--spacer-4);
        pointer-events: none;
        visibility: hidden;
        opacity: 0;
        position: absolute;
        z-index: 20;
        inset: unset;
        left: 0;
        width: 100%;
        padding: var(--spacer-5);
        transition: opacity .2s ease-in-out;
        background-color: var(--c-background);
        box-shadow: 0 0 1rem .5rem rgba(var(--c-box-shadow-rgb), 0.04);

        &--is-open {
            pointer-events: all;
            visibility: visible;
            opacity: 1;
            transform: none;
        }
    }

    &__filter-button {
        font-weight: var(--fw-regular);
        color: var(--c-black);
        background-color: transparent;
        transition: .1s ease-in-out background-color,
            .1s ease-in-out color;

        &--is-active {
            color: var(--c-white);
            background-color: var(--c-green-dark);
        }
    }

    &__filter-button-label {
        gap: var(--spacer-2);
    }

    &__label {
        @extend %heading-5;

        padding: var(--spacer-2) var(--spacer-3);
    }

    &__search-input {
        margin-block-start: var(--spacer-3);
    }
}
