@use "src/styles" as *;

.account-order-detail-tab {

    &__wrapper {
        padding-block-start: 1rem;
    }

    &__back-button {
        @extend %button--no-background;

        justify-content: start;
        width: fit-content;
        font-weight: var(--fw-regular);
        color: inherit;
        padding-inline: 0;
    }

    &__back-button-link {
        color: var(--c-green-dark);
    }

    &__renderer {
        margin-block-start: var(--spacer-4);
    }
}
